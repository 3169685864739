<template>
  <div>
    <div class="filter-wrapper">
      <div style="display: flex; align-items: center">
        <v-select
          v-model="dataSelectLastDayValue"
          :options="dataSelectOptionsLastDay"
          :filterable="false"
          :searchable="false"
          :components="{Deselect: dataComponentDeselect}"
          placeholder="Last Days"
          striped
          :class="['call-select', dataSelectLastDayValue ? 'call-select--value' : null]"
          @input="$_ajax">
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <font-awesome-icon :icon="dataIconDown" />
            </span>
          </template>
        </v-select>
        <h2
          v-if="timeZoneOffset !== null"
          style="margin: 0 10px">
          Timezone Offset: {{ timeZoneOffset }}
        </h2>
      </div>
    </div>
    <h2>{{ computedLabel }} (This vs Last)</h2>
    <call-card-report-detail :obj-card="dataStatReport" />

    <div class="table__title filter-wrapper">
      <h2>Projects ({{ dataProjectList.length || 0 }})</h2>
    </div>
    <div class="table-wrapper">
      <b-table
        hover
        fixed
        no-local-sorting
        :fields="dataMixinReportFields"
        :items="dataProjectList"
        @row-clicked="(value) => onProjectActions(value, 'view')"
        @row-hovered="onHover"
        @row-unhovered="onUnHover">
        <template
          #head(outcoming_finished_calls_count)>
          <span class="success">
            Calls
          </span>
          Finished
        </template>

        <template #head(outcoming_cancelled_calls_count)>
          <span class="danger">
            Calls
          </span>
          Cancelled
        </template>

        <template #head(auto_closed_tasks_count)>
          <span class="success">
            Closed
          </span>
          Auto Assigned
        </template>

        <template #head(auto_closed_vip_tasks_count)>
          <span class="success">
            Closed
          </span>
          VIP
        </template>

        <template #head(manually_closed_tasks_count)>
          <span class="danger">
            Closed
          </span>
          Manually
        </template>

        <template #cell(outcoming_finished_calls_count)="data">
          <span class="success">{{ data.item.outcoming_finished_calls_count }}</span>
        </template>
        <template #cell(auto_closed_vip_tasks_count)="data">
          <span class="success">{{ data.item.auto_closed_vip_tasks_count }}</span>
        </template>
        <template #cell(outcoming_cancelled_calls_count)="data">
          <span class="danger">{{ data.item.outcoming_cancelled_calls_count }}</span>
        </template>
        <template #cell(auto_closed_tasks_count)="data">
          <span class="success">{{ data.item.auto_closed_tasks_count }}</span>
        </template>
        <template #cell(manually_closed_tasks_count)="data">
          <span class="danger">{{ data.item.manually_closed_tasks_count }}</span>
        </template>

        <template #cell(isBlocked)="data">
          <font-awesome-icon
            v-if="data.item.is_blocked"
            class="tabel-icon--red"
            :icon="dataIconLock" />
          <font-awesome-icon
            v-else
            class="tabel-icon--green"
            :icon="dataIconCheck" />
        </template>
        <template #cell(calls_duration)="data">
          {{ data.item.calls_duration | dateReport }}
        </template>

        <template #cell(actions)="data">
          <call-table-report-expand-actions
            v-show="data.item.actions"
            :row="data"
            @on-action="(value) => onProjectActions(data.item, value)" />
        </template>

        <template #row-details="row">
          <b-table
            fixed
            hover
            no-local-sorting
            :fields="dataMixinReportExpandedFields"
            :items="row.item.operators_kpi"
            thead-tr-class="expand-header"
            tbody-class="expand-body"
            @row-clicked="(value) => onActions(value, 'expand_view')"
            @row-hovered="onHover"
            @row-unhovered="onUnHover">
            <template #cell(outcoming_finished_calls_count)="data">
              <span class="success">{{ data.item.outcoming_finished_calls_count }}</span>
            </template>
            <template #cell(auto_closed_vip_tasks_count)="data">
              <span class="success">{{ data.item.auto_closed_vip_tasks_count }}</span>
            </template>
            <template #cell(outcoming_cancelled_calls_count)="data">
              <span class="danger">{{ data.item.outcoming_cancelled_calls_count }}</span>
            </template>
            <template #cell(auto_closed_tasks_count)="data">
              <span class="success">{{ data.item.auto_closed_tasks_count }}</span>
            </template>
            <template #cell(manually_closed_tasks_count)="data">
              <span class="danger">{{ data.item.manually_closed_tasks_count }}</span>
            </template>

            <template #cell(isBlocked)="data">
              <font-awesome-icon
                v-if="data.item.is_blocked"
                class="tabel-icon--red"
                :icon="dataIconLock" />
              <font-awesome-icon
                v-else
                class="tabel-icon--green"
                :icon="dataIconCheck" />
            </template>
            <template #cell(calls_duration)="data">
              {{ data.item.calls_duration | dateReport }}
            </template>
            <template #cell(actions)="data">
              <call-table-report-expand-actions
                v-show="data.item.actions"
                :row="data"
                @on-action="(value) => onActions(data.item, 'expand_view')" />
            </template>
          </b-table>
        </template>
      </b-table>
    </div>

    <div class="table__title filter-wrapper">
      <h2>Operators ({{ dataStatReport.operators_count || 0 }})</h2>
    </div>
    <div class="table-wrapper">
      <b-table
        hover
        no-local-sorting
        :fields="dataMixinReportOperatorFields"
        :items="dataReportList"
        @row-clicked="(value) => onActions(value, 'view')"
        @row-hovered="onHover"
        @row-unhovered="onUnHover">
        <template
          #head(outcoming_finished_calls_count)>
          <span class="success">
            Calls
          </span>
          Finished
        </template>

        <template #head(outcoming_cancelled_calls_count)>
          <span class="danger">
            Calls
          </span>
          Cancelled
        </template>

        <template #head(auto_closed_tasks_count)>
          <span class="success">
            Closed
          </span>
          Auto Assigned
        </template>

        <template #head(auto_closed_vip_tasks_count)>
          <span class="success">
            Closed
          </span>
          VIP
        </template>

        <template #head(manually_closed_tasks_count)>
          <span class="danger">
            Closed
          </span>
          Manually
        </template>

        <template #cell(outcoming_finished_calls_count)="data">
          <span class="success">{{ data.item.outcoming_finished_calls_count }}</span>
        </template>
        <template #cell(auto_closed_vip_tasks_count)="data">
          <span class="success">{{ data.item.auto_closed_vip_tasks_count }}</span>
        </template>
        <template #cell(outcoming_cancelled_calls_count)="data">
          <span class="danger">{{ data.item.outcoming_cancelled_calls_count }}</span>
        </template>
        <template #cell(auto_closed_tasks_count)="data">
          <span class="success">{{ data.item.auto_closed_tasks_count }}</span>
        </template>
        <template #cell(manually_closed_tasks_count)="data">
          <span class="danger">{{ data.item.manually_closed_tasks_count }}</span>
        </template>

        <template #cell(isBlocked)="data">
          <font-awesome-icon
            v-if="data.item.is_blocked"
            class="tabel-icon--red"
            :icon="dataIconLock" />
          <font-awesome-icon
            v-else
            class="tabel-icon--green"
            :icon="dataIconCheck" />
        </template>
        <template #cell(calls_duration)="data">
          {{ data.item.calls_duration | dateReport }}
        </template>
        <template #cell(actions)="data">
          <call-table-report-expand-actions
            v-show="data.item.actions"
            :row="data"
            @on-action="(value) => onActions(data.item, value)" />
        </template>

        <!-- expanded operator -->
        <template #row-details="row">
          <b-table
            hover
            no-local-sorting
            :fields="dataMixinReportOperatorExpandFields"
            :items="row.item.projects_kpi"
            tbody-class="expand-body"
            @row-clicked="(value) => onProjectActions(value, 'expand_view')"
            @row-hovered="onHover"
            @row-unhovered="onUnHover">
            <template #cell(actions)="data">
              <call-table-report-expand-actions
                v-show="data.item.actions"
                :row="data"
                @on-action="(value) => onProjectActions(data.item, 'expand_view')" />
            </template>
          </b-table>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { REPORTS_GET_TEAMS, TEAMS_GET } from '../../../store/types/actions.types';

import CallCardReportDetail from '../../../components/common/cards/reportDetailCard';
import CallTableReportExpandActions from '../../../components/desktop/CallTableReportExpandActions';
import reportsOperatorTable from '../../../mixins/table/reportsOperatorTable';
import { BTable } from 'bootstrap-vue';
import { faCheck, faLock } from '@fortawesome/free-solid-svg-icons';
import helper from '../../../service/helper';
import selectReportPeriod from '../../../mixins/select/selectReportPeriod';
import projectTable from '../../../mixins/table/projectTable';
import { mapGetters } from 'vuex';

export default {
  name: 'PageReportsDetail',
  components: { CallTableReportExpandActions, CallCardReportDetail, BTable },
  mixins: [projectTable, reportsOperatorTable, selectReportPeriod],
  data () {
    const projects = {
      dataProjectList: []
    };
    return {
      ...projects,
      dataReportList: [],
      dataStatReport: {},
      dataIconLock: faLock,
      dataIconCheck: faCheck,
      timeZoneOffset: null
    };
  },
  computed: {
    ...mapGetters({
      computedIsAdmin: 'auth/isAdmin',
      computedIsModerator: 'auth/isModerator',
      computedIsOperator: 'auth/isOperator'
    }),
    computedTeamId () {
      return this.$route.params?.reportId;
    },
    computedLabel () {
      return this.dataSelectLastDayValue?.label || 'Today';
    }
  },
  async created () {
    this.$_ajax = ((options = {}) => {
      const { currentRoute: { name: pageName } = {} } = this.$router;

      const _filterKey = `_filter${pageName}`;

      if (!helper.isEmpty(this.$route.query, _filterKey)) {
        try {
          options = helper.clearObject(JSON.parse(this.$route.query[_filterKey]));

          const _arrFilter = [
            'period'
          ];

          for (const _key of _arrFilter) {
            if (!helper.isEmpty(options, _key)) {
              switch (_key) {
                case 'period':
                  this.dataSelectLastDayValue = options[_key];
                  break;
              }
            }
          }
        } catch (e) {
          console.log('Error query params');
        }
      }

      return async () => {
        if (!this.dataSelectLastDayValue) {
          this.dataSelectLastDayValue = {
            ...this.dataSelectLastDayValueDefault
          };
        }

        const { statTeam = {} } = await this.$store.dispatch(`report/${REPORTS_GET_TEAMS}`, {
          teamId: this.computedTeamId,
          params: {
            period: this.dataSelectLastDayValue?.key || 'day'
          }
        });

        this.dataStatReport = statTeam;

        const { team = {} } = await this.$store.dispatch(`team/${TEAMS_GET}`, {
          params: {
            id: this.computedTeamId
          }
        });

        this.timeZoneOffset = team.timezone_offset;

        this.dataReportList = helper.cloneDeep((statTeam?.operators || [])).map((item) => {
          item.actions = false;
          return item;
        });

        this.dataProjectList = helper.cloneDeep((statTeam?.projects || [])).map((item) => {
          item.actions = false;
          return item;
        });

        this.$router.replace({
          ...this.$router.currentRoute,
          query: {
            ...(this.$route.query || {}),
            [_filterKey]: JSON.stringify(helper.clearObject({
              period: this.dataSelectLastDayValue
            }))
          }
        }).catch(() => {});

        options = {};
      };
    })();

    await Promise.all([
      this.$_ajax()
    ]);
  },
  methods: {
    // project
    ...{
      async onProjectActions ({ id: projectId, is_active: isBlocked, name = '', project_id, project_name }, action) {
        switch (action) {
          case 'update':
            break;
          case 'view':
            this.$router.push({
              name: 'ProjectTasks',
              params: {
                projectId
              },
              query: {
                ...(this.$route.query || {}),
                projectName: name
              }
            });
            break;
          case 'expand_view':
            this.$router.push({
              name: 'ProjectTasks',
              params: {
                projectId: project_id
              },
              query: {
                ...(this.$route.query || {}),
                projectName: project_name
              }
            });
            break;
        }
      }
    },
    async  onActions ({ id: userId, is_blocked: isBlocked, name = '', operator_id, operator_name }, action) {
      switch (action) {
        case 'view':
          this.$router.push({
            name: 'UserDetail',
            params: {
              id: userId
            },
            query: {
              userName: name
            }
          });
          break;
        case 'expand_view':
          this.$router.push({
            name: 'UserDetail',
            params: {
              id: operator_id
            },
            query: {
              userName: operator_name
            }
          });
          break;
      }
    }
  }
};
</script>
