<template>
  <div class="table_actions">
    <call-tooltip
      placement="top"
      content="Info">
      <call-button
        outlet
        type="success"
        round
        not-border
        :icon="dataViewIcon"
        @click="clickAction('view')" />
    </call-tooltip>

    <call-tooltip
      v-if="isExpanded"
      placement="top"
      :content="!row.detailsShowing ? 'Show' : 'Hide'">
      <call-button
        outlet
        type="success"
        round
        not-border
        :icon="!row.detailsShowing ? dataDownIcon : dataUpIcon"
        @click="row.toggleDetails" />
    </call-tooltip>
  </div>
</template>

<script>
import CallButton from '../common/CallButton';
import { faEye, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'CallTableReportActions',
  components: { CallButton },

  props: {
    row: {
      type: Object,
      default () {
        return {};
      }
    }
  },

  data () {
    return {
      dataViewIcon: faEye,
      dataDownIcon: faChevronDown,
      dataUpIcon: faChevronUp
    };
  },

  computed: {
    isExpanded () {
      return this.row?.item.operators_kpi?.length || this.row?.item.projects_kpi?.length;
    }
  },

  methods: {
    clickAction (action) {
      this.$emit('on-action', action);
    }
  }
};
</script>
